<template>
  <div class="row h-100 justify-content-center">
    <div class="col-lg-10 col-xl-10">
      <div class="d-flex flex-column px-7 mt-15">
        <div class="row align-items-center">
          <div class="col-12 d-flex justify-content-start">
            <div class="d-flex flex-column">
              <h2 class="card-label h1 font-weight-bolder">
                {{ this.driver }}
              </h2>
              <div
                class="h6 text-break mt-2"
                style=""
                v-html="description"
              ></div>
            </div>
          </div>
        </div>
        <!--begin::Search-->
        <div class="row mb-5">
          <div class="col-xl-12">
            <form method="get" class="quick-search-form">
              <div class="input-group bg-tranparent border-0">
                <div class="input-group-prepend bg-transparent">
                  <button
                    class="input-group-text line-height-0 py-0 bg-transparent border-0"
                    v-on:click="getSearchData($event)"
                  >
                    <img
                      src="/assets/media/bipart/ico-search.png"
                      alt=""
                      class="w-100"
                    />
                  </button>
                </div>
                <input
                  type="text"
                  class="form-control py-10 h4 bg-transparent border-0 text-dark-50"
                  placeholder="Search..."
                  v-model="keywords"
                />
                <div class="input-group-append">
                  <span
                    class="input-group-text h3 px-10 text-primary font-weight-bold bg-transparent border-0"
                  >
                    {{ tot_challenges }}
                  </span>
                </div>
              </div>
            </form>
          </div>
        </div>
        <!--::Module Filter -->

        <!--begin::Elenco -->
        <div class="mb-5">
          <div class="row">
            <div
              class="col-lg-6 col-xl-3 rounded-xl"
              v-for="item in this.challenges"
              :key="item.id"
            >
              <ChallengeCard :challenge="item"></ChallengeCard>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ChallengeCard from "@/view/pages/drivers/partials/ChallengeCard";
import ApiService from "@/core/services/api.service";
import { mapGetters } from "vuex";

export default {
  name: "DriverDetail",
  data() {
    return {
      driver: this.$route.params.driver_name,
      challenges: [],
      tot_challenges: null,
      id: this.$route.params.id,
      keywords: "",
      description: "",
    };
  },
  components: {
    ChallengeCard,
    //OrganizationMenu
  },
  computed: {
    ...mapGetters(["getOrganization"]),

    isBandoZac() {
      return window.location.host.includes('bandozac.bipart.it');
    },
  },
  mounted() {
    const countAgendas = this.getOrganization?.data?.countAgendas || 0;
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: this.isBandoZac ? "Format di innovazione" : this.$t("DRIVERS.CHALLENGE"),
        route: countAgendas > 1 ? "/driver-list" : "disabled",
      },
      { title: this.driver },
    ]);
    this.getData();
  },
  methods: {
    getSearchData(event) {
      event.preventDefault();
      this.getData();
    },
    getData() {
      this.$isLoading(true);
      return new Promise((resolve, reject) => {
        let qs = `?organizationId.equals=${this.getOrganization.data.id}`;
        var endpoint = this.generateUrl(`agendas-expanded/${this.id}`, true);

        ApiService.get(this.$apiResource, endpoint + qs)
          .then((res) => {
            this.description = res.data.description;
            this.challenges = res.data.challenges.filter((x) =>
              this.keywords
                ? x.name.toLowerCase().includes(this.keywords.toLowerCase())
                : true
            );
            this.tot_challenges = this.challenges ? this.challenges.length : 0;
            resolve(true);
            this.$isLoading(false);
          })
          .catch(() => {
            reject(0);
            this.$isLoading(false);
          });
      });
    },
  },
  created() {},
};
</script>

<style scoped></style>
